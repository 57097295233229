<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    max-width="1024"
    persistent
    overlay-opacity="0.97"
    @keydown.esc="cancel"
  >
    <v-card
      v-touch:swipe.left="cancel"
      class="d-flex flex-column noscroll charcoal "
      tile
    >
      <BaseCloseDialogHeader heading="Nutrients For" @cancel="done" />
      <v-card-text
        :class="textSize + ' paper--text text-center pl-2 pt-0 pb-2'"
      >
        {{ nutrients.name }}
      </v-card-text>
      <v-card-text class="px-1" v-if="loaded">
        <v-card-title class="py-0 px-2 paper--text charcoalTileMenu">
          <span :class="textSizeLarge">
            Fats
          </span>
          <v-spacer />
          <v-checkbox
            color="paper"
            dark
            off-icon="mdi-chevron-down-circle-outline"
            on-icon="mdi-chevron-up-circle-outline"
            v-model="fatsDetails"
            label="Breakdown"
            title="Fats Breakdown"
            @change="resetToggles('fats')"
          />
        </v-card-title>
        <v-divider class="hybrid" />
        <v-sheet tile class="charcoalTile transparent pa-2 pt-4">
          <v-row dense>
            <v-col cols="6" class="">
              <v-text-field
                dark
                disabled
                color="progressActive"
                label="Total Fats (g)"
                v-model="myNutrients['FAT']"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-weight-gram"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-spacer />
            <v-col cols="6" class="">
              <v-text-field
                dark
                disabled
                color="progressActive"
                label="Cholesterol (mg)"
                v-model="myNutrients['CHOLE']"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-weight"
                @focus="$event.target.select()"
              />
            </v-col>
            <template v-if="fatsDetails">
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Saturated Fat (g)"
                  v-model="myNutrients['FASAT']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Monosaturated Fat (g)"
                  v-model="myNutrients['FAMS']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Trans Fats (g)"
                  v-model="myNutrients['FATRN']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Polyunsaturated Fat (g)"
                  v-model="myNutrients['FAPU']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
            </template>
          </v-row>
        </v-sheet>

        <v-card-title class="pa-0 px-2 mt-2 paper--text charcoalTileMenu">
          <span :class="textSizeLarge">
            Carbs
          </span>
          <v-spacer />
          <v-checkbox
            color="paper"
            dark
            off-icon="mdi-chevron-down-circle-outline"
            on-icon="mdi-chevron-up-circle-outline"
            v-model="carbsDetails"
            label="Breakdown"
            title="Carbs Breakdown"
            @change="resetToggles('carbs')"
          />
        </v-card-title>
        <v-divider class="gold darken-1" />
        <v-sheet tile class="charcoalTile transparent pa-2 pt-4">
          <v-row dense>
            <v-col cols="6" class="">
              <v-text-field
                dark
                disabled
                color="progressActive"
                label="Total Carbs (g)"
                v-model="myNutrients['CHOCDF']"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-weight-gram"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-spacer />
            <v-col cols="6" class="">
              <v-text-field
                dark
                disabled
                color="progressActive"
                label="Fiber (g)"
                v-model="myNutrients['FIBTG']"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-weight-gram"
                @focus="$event.target.select()"
              />
            </v-col>
            <template v-if="carbsDetails">
              <v-spacer />
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Total Sugar (g)"
                  v-model="myNutrients['SUGAR']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Sugar Alcohol (g)"
                  v-model="myNutrients['Sugar.alcohol']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Added Sugar (g)"
                  v-model="myNutrients['SUGAR.added']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Net Carbs (g)"
                  v-model="myNutrients['CHOCDF.net']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
            </template>
          </v-row>
        </v-sheet>
        <v-card-title class="pa-2 mt-2 paper--text charcoalTileMenu">
          <span :class="textSizeLarge">
            Proteins
          </span>
          <v-spacer />
        </v-card-title>
        <v-divider class="progressActive darken-1" />
        <v-sheet tile class="charcoalTile transparent pa-2">
          <v-row>
            <v-col cols="6" class="mt-1">
              <v-text-field
                dark
                disabled
                color="progressActive"
                label="Proteins (g)"
                v-model="myNutrients['PROCNT']"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-weight-gram"
                @focus="$event.target.select()"
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>

      <v-spacer />
      <v-card-text class="px-1">
        <v-card-title
          class="px-2 py-0 mt-2 paper--text charcoal  charcoalTileMenu"
        >
          <span :class="textSizeLarge">
            Minerals
          </span>
          <v-spacer />
          <v-checkbox
            color="paper"
            dark
            off-icon="mdi-chevron-down-circle-outline"
            on-icon="mdi-chevron-up-circle-outline"
            v-model="mineralsDetails"
            :label="mineralsDetails ? 'Less' : 'More'"
            title="More Minerals"
            @change="resetToggles('minerals')"
          />
        </v-card-title>
        <v-sheet rounded class="charcoalTileMenu transparent pa-2 pt-4">
          <v-row dense>
            <v-col cols="6" class="">
              <v-text-field
                dark
                disabled
                color="progressActive"
                label="Sodium (NA, mg)"
                ref="proteins"
                v-model="myNutrients['NA']"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-weight"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col cols="6" class="">
              <v-text-field
                dark
                disabled
                color="progressActive"
                label="Calcium (CA, mg)"
                v-model="myNutrients['CA']"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-weight"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-spacer />

            <template v-if="mineralsDetails">
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Iron (FE, mg)"
                  ref="proteins"
                  v-model="myNutrients['FE']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Magnesium (MG, mg)"
                  ref="proteins"
                  v-model="myNutrients['MG']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Phosphorus (P, mg)"
                  ref="proteins"
                  v-model="myNutrients['P']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Potassium (K, mg)"
                  ref="proteins"
                  v-model="myNutrients['K']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Zinc (ZN, mg)"
                  ref="proteins"
                  v-model="myNutrients['ZN']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
            </template>
          </v-row>
        </v-sheet>
        <v-card-title
          class="px-2 py-0 mt-2 paper--text charcoal  charcoalTileMenu"
        >
          <span :class="textSizeLarge">
            Vitamins
          </span>
          <v-spacer />
          <v-checkbox
            color="paper"
            dark
            off-icon="mdi-chevron-down-circle-outline"
            on-icon="mdi-chevron-up-circle-outline"
            v-model="vitaminsDetails"
            :label="vitaminsDetails ? 'Less' : 'More'"
            title="More Vitamins"
            @change="resetToggles('vitamins')"
          />
        </v-card-title>
        <v-sheet rounded class="charcoalTileMenu transparent pa-2 pt-4">
          <v-row dense>
            <v-col cols="6" class="">
              <v-text-field
                dark
                disabled
                color="progressActive"
                label="Vitamin D (D2+D3, µg)"
                v-model="myNutrients['VITD']"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-weight"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col cols="6" class="">
              <v-text-field
                dark
                disabled
                color="progressActive"
                label="Vitamin B-12 (µg)"
                v-model="myNutrients['VITB12']"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-weight"
                @focus="$event.target.select()"
              />
            </v-col>

            <template v-if="vitaminsDetails">
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Folate, DFE (B9, µg)"
                  v-model="myNutrients['FOLDFE']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Folate, food (B9, µg)"
                  v-model="myNutrients['FOLFD']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Folic acid (B9, µg ) "
                  v-model="myNutrients['FOLAC']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Niacin (B3, mg)"
                  v-model="myNutrients['NIA']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Riboflavin (B2, mg)"
                  v-model="myNutrients['RIBF']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Thiamin (B1, mg)"
                  v-model="myNutrients['THIA']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Vitamin B-12 (µg)"
                  v-model="myNutrients['VITB12']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Vitamin B-6 (mg)"
                  v-model="myNutrients['VITB6A']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Vitamin A, RAE (µg)"
                  v-model="myNutrients['VITA_RAE']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Vitamin C (mg)"
                  v-model="myNutrients['VITC']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />

              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Vitamin E (mg)"
                  v-model="myNutrients['TOCPHA']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="mt-n4">
                <v-text-field
                  dark
                  disabled
                  color="progressActive"
                  label="Vitamin K (µg)"
                  v-model="myNutrients['VITK1']"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
            </template>
          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import myNutrients from '@/json/nutrients.json'

export default {
  components: {},
  name: 'ViewNutrients',
  mixins: [validationMixin, util],
  data() {
    return {
      loaded: false,
      myNutrients: null,
      loadedNutrients: myNutrients,
      fatsDetails: false,
      carbsDetails: false,
      vitaminsDetails: false,
      mineralsDetails: false
    }
  },
  beforeMount() {
    this.reset()
  },
  mounted() {
    this.loaded = true
    this.setNutrients()
    this.$nextTick(() => {
      window.addEventListener('message', this.receiveMessage)
    })
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
    this.reset()
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    nutrients: {
      type: Object
    }
  },
  computed: {
    ...appConfig
  },
  watch: {
    show(val) {
      this.$store.dispatch('session/toggleModal', val)
      if (!val) this.reset()
      else {
        if (this.loaded) this.setNutrients()
        this.scrollToTop()
      }
    }
  },

  methods: {
    resetToggles(what) {
      if (what != 'fats') this.fatsDetails = false
      if (what != 'carbs') this.carbsDetails = false
      if (what != 'minerals') this.mineralsDetails = false
      if (what != 'vitamins') this.vitaminsDetails = false
    },
    done() {
      this.$emit('done', this.myNutrients)
      this.reset()
    },
    setNutrients() {
      this.myNutrients = Object.assign({}, this.myNutrients, this.nutrients)
      Object.keys(this.myNutrients).forEach(element => {
        this.myNutrients[element] = this.twoDigits(this.myNutrients[element])
      })
    },
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.done()
      }
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    reset() {
      this.myNutrients = JSON.parse(JSON.stringify(this.loadedNutrients))
    }
  }
}
</script>
<style scoped>
.edamam {
  z-index: 99;
  position: absolute;
  left: 160px;
  top: 6px;
}
</style>
